.form-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--black-color);
}

.form-container h1 {
  margin-bottom: 60px;
  color: var(--white-color);
}

.contact-form {
  width: 60%;
  max-width: 600px; /* Adjust max-width as needed */
  padding: 20px;
  background-color: var(--black-color);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



.form-control {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  border-radius: 5px;
  color: var(--black-color); 
}

.label {
  margin-bottom: 5px;
  color: var(--white-color); 
}

@media (max-width: 768px) {
  .form-container {
    margin-top: 50px;
  }
  .contact-form {
    width: 70%;
  }
}

.custom-confirm-button {
  background-color: black !important;
  color: white !important;
  border: none !important;
}


.alert-confirm-button {
  background-color: black !important;
  color: white !important;
  border: none !important;
}

.alert-title {
  text-wrap: nowrap;
  font-size: 20px !important; /* Set the desired font size here */
}

.alert-popup {
  font-size: 12px !important; /* Set the desired font size for the entire popup */
}
