.usecase {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh; /* Ensure full viewport height */
  max-width: 100vb;
}

.content {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr; /* Title on the left, image and description on the right */
  gap: 20px; /* Space between title, image, and description */
  align-items: center;
  transition: transform 0.5s ease-in-out;
}

.use-case-title {
  writing-mode: vertical-rl; /* Vertical text */
  transform: rotate(180deg); /* Flip text */
  font-size: 1.4rem;
  font-weight: 500;
  align-self: center;
  justify-self: center;
  margin-bottom: 150px;
  grid-row: span 2; /* Span title across two rows */
}


.use-case-image {
  width: 500px;
  height: 350px;
  border-radius: 50px;
  transition: transform 0.5s ease-in-out;
}

.use-case-description {
  grid-column: 2; /* Align description in the second column */
  grid-row: 2; /* Position description in the second row */
  text-align: center;
  text-wrap: wrap;
  font-size: 15px;
  font-weight: 500;
  max-width: 450px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.content:hover .use-case-description {
  opacity: 1;
}

.content:hover .use-case-image {
  transform: translateY(-20px); /* Move title and image up */
}

@media (max-width: 768px) {
  .use-case-description {
    opacity: 1;
    font-size: 12px;
    max-width: 300px;
  }
  .use-case-image {
    width: 280px;
    height: 200px;
    border-radius: 40px;
    transition: transform 0.5s ease-in-out;
  }
  .use-case-title {
    font-size: 1rem;
  }
  .usecase {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100vh; /* Ensure full viewport height */
    margin-bottom: 50px;
  }
}


