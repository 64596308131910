.introductory {
  box-sizing: border-box;
  padding: 0px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
  background-color: var(--black-color);
}

.content-1 {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  display: flex;
  width: 100%;
}

.video-section {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: calc(100% - 400px); /* Full width minus 400px */
  margin-left: 400px; /* Start after 400px */
}

.video-int {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  overflow: hidden;
  width: 100%;
  height: 90vh;
  position: relative;
  opacity: 0.8;
}

.intro-video {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px 0px 0px 60px;
}

.text-section {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  position: absolute;
  top: 54%;
  left: 1%;
  transform: translateY(-50%);
}

.text-section h1 {
  box-sizing: border-box;
  padding: 0px;
  scroll-behavior: smooth;
  margin: 0px;
  color: var(--white-color);
  font-weight: 600;
  font-size: 80px;
  margin-top: 350px;
  line-height: 80px;
  position: relative;
  z-index: 1;
  left: 145px;
}

.text-section h2 {
  box-sizing: border-box;
  padding: 0px;
  scroll-behavior: smooth;
  margin: 0px;
  font-weight: 400;
  width: 1000px;
  color: var(--white-color);
  font-size: 22px;
  padding-left: 150px;
  padding-top: 30px;
  line-height: 35px;
  position: relative;
  z-index: 1;
}


@media (max-width: 1024px) {
  .video-section {
    padding-right: 100px; /* Adjust as needed for smaller screens */
  }
}

@media (max-width: 768px) {
  .video-section {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    position: relative;
  }

  .video-int {
    width: 80%;
    height: 400px; 
    margin: 0;
  }

  .intro-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0; /* Remove border radius */
    border-radius: 40px;
  }

  .text-section {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0 10px;
  }

  .text-section h1 {
    font-size: 25px;
    line-height: 1.2;
    margin: 0;
    margin-top: 200px;
    padding-left: 0;
  }

  .text-section h2 {
    width: 80%;
    font-size: 15px;
    line-height: 25px;
    margin-left: 180px;
    text-wrap: wrap;
    padding-left: 0;
  }
}
