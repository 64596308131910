
.RacesWrapper {
  height: 100vh;
}

.Races {
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 100px;
  gap: 10px;
}

@media (max-width: 600px) {
  .Races{
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0;
  }
}

.UseCaseHeading {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 100px;
  margin-bottom: 0px;
}

.UseCaseHeading h2 {
  font-size: 3rem;
  margin-right: 100px;
}

.UseCaseHeading h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-right: 100px;
}

@media (max-width: 768px) {
  .UseCaseHeading{
    align-items: center;
    text-align: center;
  }
}

.space {
  height: 2vh;
}
