:root{
  --black-color : #000;
  --white-color : #fff;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: var(--black-color);
  color: var(--white-color);
  overflow-x: hidden;
}

hr{
  color: var(--black-color);
  margin-bottom: 100px;
}

.hidden{
  overflow: hidden;
}