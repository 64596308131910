.footer-basic {
  padding: 40px 0;
  color: var(--white-color);
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.footer-basic .social > a:hover {
  transform: scale(1.1);
  opacity: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

/* Mobile responsive styles */
@media (max-width: 600px) {
  .footer-basic ul {
    font-size: 16px;
  }

  .footer-basic .social > a {
    font-size: 20px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}
