.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: var(--black-color);
  color: var(--white-color);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo img {
  margin: 0;
  height: 4.5rem;
}

.nav-actions {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .custom-btn {
    display: none;
  }
}

.custom-btn {
  text-wrap: nowrap;
  margin-right: 2rem;
  padding: 0.5rem 1rem;
  background-color: var(--black-color);
  color: var(--white-color);
  border: 2px solid var(--white-color);
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.5s ease;
}

.custom-btn:hover,
.custom-btn:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--black-color);
  transform: translateY(-0.25em);
}

.menu-icon {
  width: 2.5rem;
  height: 1.8rem;
  position: relative;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-icon div {
  width: 100%;
  height: 4px;
  background-color: var(--white-color);
  transition: all 0.5s ease;
}

.menu-icon.open div:nth-child(1) {
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
}

.menu-icon.open div:nth-child(2) {
  opacity: 0;
}

.menu-icon.open div:nth-child(3) {
  transform: rotate(-45deg);
  position: absolute;
  top: 10px;
}

.sidebar {
  z-index: 40;
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 750px;
  background: linear-gradient(180deg, #000 0%, #4F4F4F 100%);
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: right 0.5s ease-in-out;
}

.sidebar.open {
  right: 0;
}

.sidebar-link-menu{
  padding: 3rem 2rem;
  text-decoration: none;
  color: var(--white-color);
  font-size: 3rem;
}

.sidebar-link {
  padding: 1rem 2rem;
  text-decoration: none;
  color: var(--white-color);
  font-size: 2rem;
  transition: color 0.2s;
}

.sidebar-link:hover {
  color: var(--white-color);
  font-size: 2.5rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .nav{
    max-width: 100vb;
  }
}