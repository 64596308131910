.why {
  padding: 0 64px;
  text-align: center;
  background: var(--black-color);
  min-height: 100vh;
}

.why h1 {
  font-size: 48px;
  margin-top: 80px;
  margin-bottom: 32px;
  color: var(--white-color);
}

.why h3 {
  font-size: 20px;
  margin-bottom: 48px;
  line-height: 1.6;
  color: var(--white-color);
}

.why .key-points {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display 3 cards in a row */
  gap: 32px;
  justify-items: center;
}

.why .card {
  background-color: var(--black-color);
  border: 2px solid var(--white-color);
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left; /* Ensure text alignment is left */
}

.why .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.why .card h4 {
  font-size: 24px;
  margin-bottom: 16px;
  color: var(--white-color);
}

.why .card p {
  font-size: 16px;
  color: var(--white-color);
}

@media (max-width: 768px) {
  .why {
    padding: 0 32px;
  }

  .why h1 {
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 16px;
  }

  .why h3 {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .why .key-points {
    grid-template-columns: repeat(1, 1fr); /* Display 1 card per row on smaller screens */
  }

  .why .card {
    padding: 24px;
  }

  .why .card h4 {
    font-size: 20px;
  }

  .why .card p {
    font-size: 14px;
  }
}
