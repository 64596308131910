.btnContainer {
  width: 100%;
  height: 40px;
  text-align: center;
  justify-self: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

#button {
  outline: none;
  height: 40px;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  background: var(--white-color);
  border: 2px solid var(--white-color);
  color: var(--black-color);
  letter-spacing: 1px;
  text-shadow: 0;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.25s ease;
  position: relative; /* Ensure relative positioning for absolute icon */
}

#button:hover {
  color: var(--white-color);
  background: var(--black-color);
  border: 2px solid var(--white-color);
}

#button:active {
  letter-spacing: 2px;
}

#button.onclic {
  width: 40px;
  border-color: var(--white-color);
  border-width: 3px;
  font-size: 0;
  background-color: var(--black-color);
  border-left-color:var(--white-color);
  animation: rotating 2s 0.25s linear infinite;
}

#button.onclic:hover {
  color: var(--white-color);
  background: var(--black-color);
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#button.validate {
  font-size: 13px;
  color: var(--white-color);
  background: var(--black-color);
  display: flex; /* Ensure flex for aligning icon */
  justify-content: center;
  align-items: center;
}

#button.validate .fa-check {
  margin-left: 5px; /* Adjust spacing as needed */
}

.faIcon{
  margin-left: 10px;
}