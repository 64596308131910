.custom-input {
  position: relative;
  margin-bottom: 20px;
}

input[type='email'],
input[type='text'],
textarea {
  width: 100%;
  padding: 10px;
  background-color: var(--black-color);
  color: var(--white-color);
  border: 2px solid var(--white-color); /* White border for all inputs */
  transition: border-color 0.2s ease-out; /* Smooth transition for border color */
  margin-bottom: 20px;
  border-radius: 10px;
}

input[type='email']:focus,
input[type='text']:focus,
textarea:focus {
  border-color: var(--white-color); /* White border on focus */
  outline: none; /* Remove default outline */
  
}

label {
  position: absolute;
  left: 10px;
  top: 10px;
  color: var(--white-color);
  background-color: var(--black-color);
  padding: 0 5px;
  font-size: 13px;
  transition: 0.4s ease-out;
}

.custom-input.focused label,
.custom-input.focused input:not(:placeholder-shown) + label,
.custom-input.focused textarea:not(:placeholder-shown) + label {
  top: -10px;
  left: 5px;
  font-size: 13px;
  background-color: var(--black-color);
  padding: 0 3px;
  color: var(--white-color);
}

