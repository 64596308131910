.loading-container {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-color); /* Use black background */
  z-index: 10000; /* Ensure it covers everything */
}
