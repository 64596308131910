*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.buttons {
  text-align: center;
  border-radius: 30px;
}

.blob-btn {
  position: relative;
  margin-right: 40px;
  padding: 10px 26px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s, transform 0.2s;
  cursor: pointer;
  border-radius: 30px;
}

.blob-btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--white-color);
  border-radius: 30px;
}

.blob-btn:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  border-radius: 30px;
}

.blob-btn:hover {
  color: var(--black-color);
}

.blob-btn:hover:after {
  left: 0;
  top: 0;
  transition: all 0.3s;
}

.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: var(--black-color);
}

.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url('#goo');
}

.blob-btn__blob {
  position: absolute;
  top: 2px;
  width: calc(100% / 4);
  height: 100%;
  background: var(--white-color);
  color: var(--black-color);
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.4);
  transition: transform 0.45s;
}

.blob-btn__blob:nth-child(1) {
  left: 0;
  transition-delay: 0s;
}

.blob-btn__blob:nth-child(2) {
  left: calc(100% / 4);
  transition-delay: 0.08s;
}

.blob-btn__blob:nth-child(3) {
  left: calc(200% / 4);
  transition-delay: 0.16s;
}

.blob-btn__blob:nth-child(4) {
  left: calc(300% / 4);
  transition-delay: 0.24s;
}

.blob-btn:hover .blob-btn__blob {
  transform: translateZ(0) scale(1.4);
}

@media (max-width: 768px) {
  .blob-btn {
    display: none;
  }
}

/* Add the animation for click */
.blob-btn.clicked {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.blob-btn.clicked .blob-btn__blobs {
  animation: blob-click 0.4s forwards;
}

@keyframes blob-click {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
